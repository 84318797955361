import {ApiService} from '@/services/ApiService'

const URL = '/api/user'

class UserService extends ApiService {
  constructor(){
      super()
  }
  getUrl(){
      return URL
  }
}

export default new UserService()