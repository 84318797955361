<template>
    <div class="modal-body">
        <template v-if="loading">
            <i class="fas fa-spinner fa-spin"></i> Even wachten...
        </template>
        <template v-else>
            <FormItem id="naam" :label="'Naam'" v-model="user.name"/>
            <FormItem id="telefoon" :label="'Telefoon nr.'" :type="'phone'" v-model="user.phone"/>
            <FormItem id="eaddress" :label="'E-mail'" v-model="user.email"/>
            <FormItem id="geheim" :label="'Wachtwoord'" v-model="user.password"/>
            <FormItem id="role_id" :label="'Type'" :type="'select'" v-model="user.role_id" :options="[{'id': 1, 'name': 'Administrator'},{'id': 2, 'name': 'Gebruiker'}]"/>
            <template v-if="user.role_id == 2">
                <FormItem id="opby" :label="'Koppelingen'" type="livesearch-list" v-model="user.connections" :options="connections" :content="user.connections.name" :required="false"/>
            </template>
        </template>
    </div>
</template>

<script>

    export default {
        name: 'UserFormComponent',
        props: ['itemData', 'connections'],
        data() {
            return {
                user: {
                    'id': null,
                    'name':'',
                    'email':'',
                    'phone':'',
                    'password':'',
                    'role_id': 2,
                    'connections': []
                },
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.user = val    
            },
        },
        methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');

                return valid;
            },
            getData() {
                return this.user
            },
            reset() {
                 this.user = {
                    'id': null,
                    'name':'',
                    'email':'',
                    'phone':'',
                    'password':'',
                    'role_id': 2,
                    'connections': []
                },
                this.loading = false
                this.invalid = {}
            },
            setData(data) {
                this.user = data
            },
            addRelColumn(id, name){
                const index = this.user.connections.findIndex(uc => uc.id == id);
                const item = this.connections.find(connection => connection.id == id);
                this.user.connections[index].name = item.name;
            },
            deleteRelColumn(e, c, item){
                for(let connection in this.user.connections){
                    delete this.user.connections[connection][item.id];
                }
            },
        },
        mounted () {
            this.reset()
        }
    }
</script>
